import { AxiosInstance } from 'axios';
import urlcat from 'urlcat';

/**
 * Returns the export files.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the files from.
 * @param device
 * @param from Begin datetime of the file to be generated, normally the
 * start of a day.
 * @param to End datetime of the file to be generated, normally the end
 * of a day.
 */
export async function getExport(
  api: AxiosInstance,
  bess: string,
  device: 'pcs' | 'bms' | 'meters' | 'fv' | 'sensors' | 'actuators' | 'gen' | 'cccc' | 'bstring1' | 'electropost' | '',
  from: Date,
  to: Date
): Promise<any> {
  return await api
    .get<any>(urlcat('/besses/:bess/export/:device', { bess, device, from, to }), { responseType: 'blob' })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

/**
 * Returns the export files.
 *
 * @param api Axios instance with correct headers.
 * @param bess The id of the bess to fetch the files from.
 * @param device
 * @param from Begin datetime of the file to be generated, normally the
 * start of a day.
 * @param to End datetime of the file to be generated, normally the end
 * of a day.
 */
export async function getUrl(
  api: AxiosInstance,
  bess: string,
  device: 'pcs' | 'bms' | 'meters' | 'fv' | 'sensors' | 'actuators' | 'gen' | 'cccc' | 'bstring1' | 'electropost' | '',
  from: Date,
  to: Date
): Promise<any> {
  return await api
    .get<any>(urlcat('/besses/:bess/export/:device', { bess, device, from, to }))
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}
